import { ask, condition, run, say } from "../../bot/bot-schema";
import lastFeeling from '../../actions/last-feeling';
import toLowers from '../../actions/to-lowers';
import randomOption from "../../actions/random-option";
import getContext from '../../actions/get-context';
export default {
    cbt: [
        getContext({ key: "feeling", from: "earliest", out: "first_feeling" }),
        getContext({ key: "feeling", from: "latest", out: "last_feeling" }),
        condition(function (_a) {
            var first_feeling = _a.first_feeling, last_feeling = _a.last_feeling;
            return first_feeling && first_feeling !== last_feeling;
        }, say('When we first spoke you were feeling {{ first_feeling }}')),
        condition(function (_a) {
            var last_feeling = _a.last_feeling;
            return last_feeling;
        }, say('Last time you were feeling {{ last_feeling }}')),
        randomOption({ out: "feeling", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var feeling = _a.feeling;
            return feeling === "option1";
        }, say("How are you feeling today?")),
        condition(function (_a) {
            var feeling = _a.feeling;
            return feeling === "option2";
        }, say("What's on your mind?")),
        condition(function (_a) {
            var feeling = _a.feeling;
            return feeling === "option3";
        }, say("What is currently on your mind?")),
        ask('feeling'),
        lastFeeling({ input: "feeling" }),
        toLowers({ input: "feeling" }),
        run('response0'),
        run('response3'),
        run('response4'),
        run('response5'),
        run('response6'),
        run('response7'),
        run('response8'),
        run('response9'),
        run('response10'),
        run('response11'),
        run('response12'),
        run('response13'),
        run('bye'),
    ],
    cbt_feeling: [
        randomOption({ out: "cbt_feeling", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option1";
        }, say("How are you feeling today?")),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option2";
        }, say("What's on your mind at the moment?")),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option2";
        }, say("How have you been feeling?")),
        ask('feeling'),
        // secondperson ('feeling'),
    ],
    response1: [
        randomOption({ out: "response2", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response2 = _a.response2;
            return response2 === "option1";
        }, say("So when you say {{ feeling }}, tell me a bit more about that")),
        condition(function (_a) {
            var response2 = _a.response2;
            return response2 === "option2";
        }, say("So when you say {{ feeling }}, please share a bit more about that")),
        condition(function (_a) {
            var response2 = _a.response2;
            return response2 === "option3";
        }, say("So when you said {{ feeling }}, please say a bit more about why you are feeling that way")),
        ask('response1'),
    ],
    response2: [
        randomOption({ out: "response2", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response2 = _a.response2;
            return response2 === "option1";
        }, say("And how did that make you feel?")),
        condition(function (_a) {
            var response2 = _a.response2;
            return response2 === "option2";
        }, say("What thoughts emerged for you when you reflect on those feelings?")),
        condition(function (_a) {
            var response2 = _a.response2;
            return response2 === "option3";
        }, say("What did you feel about that?")),
        ask('response2'),
    ],
    response3: [
        randomOption({ out: "response3", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response3 = _a.response3;
            return response3 === "option1";
        }, say("And can you tell me a bit more about the circumstances that may have caused you to feel that way?")),
        condition(function (_a) {
            var response3 = _a.response3;
            return response3 === "option2";
        }, say("And can you tell me a bit more about the situation that may have caused you to feel that way?")),
        condition(function (_a) {
            var response3 = _a.response3;
            return response3 === "option3";
        }, say("And can you tell me a bit more about what has been happening that may have caused you to feel that way?")),
        ask('response3'),
    ],
    response4: [
        randomOption({ out: "response4", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response4 = _a.response4;
            return response4 === "option1";
        }, say("And what do you feel are some of the more positive aspects in your life at the moment?")),
        condition(function (_a) {
            var response4 = _a.response4;
            return response4 === "option2";
        }, say("And what do you feel are some of the more supportive aspects in your life at the moment?")),
        condition(function (_a) {
            var response4 = _a.response4;
            return response4 === "option3";
        }, say("And what do you feel are some of the more helpful aspects in your life at the moment?")),
        ask('response4'),
    ],
    response5: [
        randomOption({ out: "response5", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response5 = _a.response5;
            return response5 === "option1";
        }, say("And what would you like the most positive outcome to be?")),
        condition(function (_a) {
            var response5 = _a.response5;
            return response5 === "option2";
        }, say("And what do you think would be the best outcome for you?")),
        condition(function (_a) {
            var response5 = _a.response5;
            return response5 === "option3";
        }, say("And what would your preferred outcome be?")),
        ask('response5'),
    ],
    response6: [
        randomOption({ out: "response6", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response6 = _a.response6;
            return response6 === "option1";
        }, say("And how do you think you can actually make that happen?")),
        condition(function (_a) {
            var response6 = _a.response6;
            return response6 === "option2";
        }, say("And what steps can you take to make that actually happen?")),
        condition(function (_a) {
            var response6 = _a.response6;
            return response6 === "option3";
        }, say("And what would you need to do for that to happen?")),
        ask('response6'),
    ],
    response7: [
        randomOption({ out: "response7", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response7 = _a.response7;
            return response7 === "option1";
        }, say("And how will you know you have made that actually happen?")),
        condition(function (_a) {
            var response7 = _a.response7;
            return response7 === "option2";
        }, say("And what will be the sign that you have made that happen?")),
        condition(function (_a) {
            var response7 = _a.response7;
            return response7 === "option3";
        }, say("And how you will realise that you have made that happen?")),
        ask('response7'),
        // secondperson "response7"
    ],
    response8: [
        randomOption({ out: "response8", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response8 = _a.response8;
            return response8 === "option1";
        }, say("So when you say {{ response7 }} about this situation, tell me a bit more about that")),
        condition(function (_a) {
            var response8 = _a.response8;
            return response8 === "option2";
        }, say("And tell me a bit more about the situation, when you say {{ response7 }} ")),
        condition(function (_a) {
            var response8 = _a.response8;
            return response8 === "option3";
        }, say("So you said that {{ response7 }} - tell me a bit more about that")),
        ask('response8'),
    ],
    response9: [
        randomOption({ out: "response9", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response9 = _a.response9;
            return response9 === "option1";
        }, say("And what do you think the outcome of those actions might be in the future?")),
        condition(function (_a) {
            var response9 = _a.response9;
            return response9 === "option2";
        }, say("And how do see the outcome of your actions unfolding in the future?")),
        condition(function (_a) {
            var response9 = _a.response9;
            return response9 === "option3";
        }, say("And what does the future look like if you take those actions?")),
        ask('response9'),
    ],
    response10: [
        randomOption({ out: "response10", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response10 = _a.response10;
            return response10 === "option1";
        }, say("And what might prevent you from being able to take those actions?")),
        condition(function (_a) {
            var response10 = _a.response10;
            return response10 === "option2";
        }, say("And what challenges do you see in taking those actions?")),
        condition(function (_a) {
            var response10 = _a.response10;
            return response10 === "option3";
        }, say("And what might prevent you from taking those actions?")),
        ask('response10'),
    ],
    response11: [
        randomOption({ out: "response11", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response11 = _a.response11;
            return response11 === "option1";
        }, say("And how might you overcome those challenges as you move towards action?")),
        condition(function (_a) {
            var response11 = _a.response11;
            return response11 === "option2";
        }, say("And what might be your strategy to overcome those challenges?")),
        condition(function (_a) {
            var response11 = _a.response11;
            return response11 === "option3";
        }, say("And how will you actively engage with those challenges and overcome them?")),
        ask('response11'),
        // secondperson "response11"
    ],
    response12: [
        randomOption({ out: "response12", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response12 = _a.response12;
            return response12 === "option1";
        }, say("So when you think about overcoming those challenges, when you mentioned {{ response11 }}, please speak a bit more about that")),
        condition(function (_a) {
            var response12 = _a.response12;
            return response12 === "option2";
        }, say("And so when you mentioned {{ response11 }} in overcoming those challenges, tell me more about that")),
        condition(function (_a) {
            var response12 = _a.response12;
            return response12 === "option3";
        }, say("OK - so And so when you mentioned {{ response11 }} in dealing with those challenges, tell me more about that")),
        ask('response12'),
    ],
    response13: [
        randomOption({ out: "response13", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response13 = _a.response13;
            return response13 === "option1";
        }, say("That’s interesting to hear, thanks. And of those challenges, which one will be the most satisfying to overcome for you?")),
        condition(function (_a) {
            var response13 = _a.response13;
            return response13 === "option2";
        }, say("And what will be the most satisfying challenge to overcome for you?")),
        condition(function (_a) {
            var response13 = _a.response13;
            return response13 === "option3";
        }, say("And which challenge will be the most satisfying for you, when you overcome it?")),
        ask('response13'),
    ],
    response14: [
        randomOption({ out: "response14", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response14 = _a.response14;
            return response14 === "option1";
        }, say("And what resources do you feel you will need to successfully make that happen?")),
        condition(function (_a) {
            var response14 = _a.response14;
            return response14 === "option2";
        }, say("And what resources will be required for your success?")),
        condition(function (_a) {
            var response14 = _a.response14;
            return response14 === "option3";
        }, say("And what resources will you need to successfully make that happen?")),
        ask('response14'),
    ],
    response15: [
        randomOption({ out: "response15", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response15 = _a.response15;
            return response15 === "option1";
        }, say("Who can you ask for help to make this happen for you?")),
        condition(function (_a) {
            var response15 = _a.response15;
            return response15 === "option2";
        }, say("Who can help to make this happen for you?")),
        condition(function (_a) {
            var response15 = _a.response15;
            return response15 === "option3";
        }, say("Who can you help you to make your way through this?")),
        ask('response15'),
    ],
    response16: [
        randomOption({ out: "response16", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response16 = _a.response16;
            return response16 === "option1";
        }, say("this is Tangential Looping Step 1 in action - Option 1")),
        condition(function (_a) {
            var response16 = _a.response16;
            return response16 === "option2";
        }, say("this is Tangential Looping Step 1 in action - Option 2")),
        condition(function (_a) {
            var response16 = _a.response16;
            return response16 === "option3";
        }, say("this is Tangential Looping Step 1 in action - Option 3")),
        ask('response16'),
    ],
    response17: [
        randomOption({ out: "response17", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response17 = _a.response17;
            return response17 === "option1";
        }, say("this is Tangential Looping Step 2 in action - Option 1")),
        condition(function (_a) {
            var response17 = _a.response17;
            return response17 === "option2";
        }, say("this is Tangential Looping Step 2 in action - Option 2")),
        condition(function (_a) {
            var response17 = _a.response17;
            return response17 === "option3";
        }, say("this is Tangential Looping Step 2 in action - Option 3")),
        ask('response17'),
    ],
    response181920: [
        randomOption({ out: "response18", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response18 = _a.response18;
            return response18 === "option1";
        }, say("this is Tangential Looping Step 3 in action - Option 1")),
        condition(function (_a) {
            var response18 = _a.response18;
            return response18 === "option2";
        }, say("this is Tangential Looping Step 3 in action - Option 2")),
        condition(function (_a) {
            var response18 = _a.response18;
            return response18 === "option3";
        }, say("this is Tangential Looping Step 3 in action - Option 3")),
        ask('response18'),
        randomOption({ out: "response19", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response19 = _a.response19;
            return response19 === "option1";
        }, say("this is Tangential Looping Step 4 in action - Option 1")),
        condition(function (_a) {
            var response19 = _a.response19;
            return response19 === "option2";
        }, say("this is Tangential Looping Step 4 in action - Option 2")),
        condition(function (_a) {
            var response19 = _a.response19;
            return response19 === "option3";
        }, say("this is Tangential Looping Step 4 in action - Option 3")),
        ask('response19'),
        randomOption({ out: "response20", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var response20 = _a.response20;
            return response20 === "option1";
        }, say("this is Tangential Looping Step 5 in action - Option 1")),
        condition(function (_a) {
            var response20 = _a.response20;
            return response20 === "option2";
        }, say("this is Tangential Looping Step 5 in action - Option 2")),
        condition(function (_a) {
            var response20 = _a.response20;
            return response20 === "option3";
        }, say("this is Tangential Looping Step 5 in action - Option 3")),
        ask('response20'),
    ],
    bye: [
        randomOption({ out: "bye", options: ["option1", "option2", "option3"] }),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option1";
        }, say("Thanks for sharing your feelings with me today, bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option2";
        }, say("Thanks for that conversation, I hope you found it useful. Bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option3";
        }, say("Thanks for that conversation, I hope you found it of value. Goodbye, until the next time.")),
        ask('bye'),
    ],
};
// # dialog user_bye
