import React from 'react'
import { Link } from 'react-router-dom';
import modules from '../modules';

const Home = () => {
  const friendlyName = process.env.FRIENDLY_NAME;

  return (<>
    <h1 className='text-3xl pb-6'>Welcome to <strong>{friendlyName}</strong></h1>
    <h2 className='text-xl pb-10'>Select a module from the grid below:</h2>
    <div className='min-h-max grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 auto-cols-auto content-center'>
      {modules.map((module) => (
        <div
          key={module.name}
          className=''
        >
          <Link id={`module-${module.name}`} className='min-h-full inline-block' to={module.path}>
            <div className='h-48 w-48 bg-[#b9ceff] text-black text-center grid place-content-center cursor-pointer rounded-lg text-2xl shadow-lg hover:shadow-2xl'>
              {module.name}
            </div>
          </Link>
        </div>
      ))}
    </div>
  </>)
}
export default Home;