import { Button, Input, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { decrypt, encrypt } from '../utils/encryption';
import { useAppContext } from './StateProvider';
import { box } from "tweetnacl";
import { encodeBase64 } from "tweetnacl-util";
import { awaitTimeout } from '../utils/await-timeout';

const api_uri = process.env.API_URI;

const ChatSync = (props) => {
  const context = useAppContext();
  const [chatHistory, setChatHistory] = context.chatHistory;
  const [keys, setKeys] = context.keys;
  const [privateKey, setPrivateKey] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [userId, setUserId] = useState('default');
  const [saved, setSaved] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let publicKey;
    let privateKey;

    if (keys) {
        publicKey = keys.publicKey;
        privateKey = keys.privateKey
    }
    else{
      const keypair = box.keyPair();
      publicKey = encodeBase64(keypair.publicKey);
      privateKey = encodeBase64(keypair.secretKey)
      
    }

    setPublicKey(publicKey);
    setPrivateKey(privateKey);
  }, []);

  useEffect(() => {
    setKeys({
      publicKey,
      privateKey
    });
  }, [privateKey, publicKey])

  const onLoad = async () => {
    setLoaded(false);
    await awaitTimeout(500);

    const request = await fetch(`${api_uri}/conversations/${userId}`);
    const conversations = await request.json();

    const decrypted = conversations.map((c) => {
      return [c.conversationId, JSON.parse(decrypt(privateKey, c))];
    });
    const history = Object.fromEntries(decrypted);
    setChatHistory(history);
    setLoaded(true);
  }

  const onSave = async () => {
    setSaved(false);
    await awaitTimeout(500);

    const conversations = Object.values(chatHistory).filter((chat) => chat.messages.length);
    const saveAll = Promise.all(conversations.map(async (c) => {
      const encrypted = encrypt(publicKey, JSON.stringify(c));

      const conversation = {
        conversationId: c.conversationId,
        ...encrypted
      };

      fetch(`${api_uri}/conversation/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(conversation),
        method: 'POST',
      });
    }));

    await saveAll;
    setSaved(true);
  }

  return (
    <>
      <div className='overflow-y-auto flex flex-col'>
        <Typography
          variant='h5'
        >
          Chat Sync
        </Typography>
        <div className='w-full pt-4'>
          <label htmlFor='userId' className='font-bold mr-2'>UserId</label>
          <input
            id="userId"
            className='border border-black rounded-md p-2'
            onChange={(ev) => setUserId(ev.target.value)}
            value={userId}
          />
        </div>

        <div className='w-full pt-4'>
          <label htmlFor='privateKey' className='font-bold mr-2'>Private Key</label>
          <input
            id="privateKey"
            className='border border-black rounded-md p-2'
            onChange={(ev) => setPrivateKey(ev.target.value)}
            value={privateKey}
          />
        </div>

        <div className='w-full pt-4'>
          <label htmlFor='publicKey' className='font-bold mr-2'>Public Key</label>
          <input
            id="publicKey"
            className='border border-black rounded-md p-2'
            onChange={(ev) => setPublicKey(ev.target.value)}
            value={publicKey}
          />
        </div>

        <div className='w-full pt-4'>
          <Button
            onClick={onLoad}
            style={{
              backgroundColor: 'black',
              color: 'white',
              textTransform: 'none'
            }}
          >
            Load
          </Button>
          {loaded && <p>Loaded!</p>}
        </div>
        <div className='w-full pt-4'>
          <Button
            onClick={onSave}
            style={{
              backgroundColor: 'black',
              color: 'white',
              textTransform: 'none'
            }}
          >
            Save
          </Button>
          {saved && <p>Saved!</p>}
        </div>
      </div>
    </>
  );
}

export default ChatSync;
