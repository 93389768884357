import React, { useEffect, useState } from 'react'
import { AppContextProvider } from './StateProvider';
import ChatHistory from './ChatHistory';
import ChatSync from './ChatSync';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import modules from '../modules';
import Home from './Home';
import { Box, Tabs, Tab } from '@mui/material';

const AppInner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const singleModule = () => modules.length === 1;
  const [value, setValue] = useState(undefined);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const path = location.pathname;
    const currentModule = modules.find((m) => m.path === path);
    setValue(currentModule?.path);
  }, [location]);

  return (
    <div className='grid grid-cols-6' >
      <div className='col-start-2 col-end-6 pt-6'>

        {!!value &&
          <Box className='mb-6'>
            <Tabs value={value} onChange={handleChange} aria-label="tabs" centered>
              {modules.map((module, i) => (
                <Tab key={i} label={module.name} value={module.path} />
              ))}

              <Tab label="History" value={'/history'} />
            </Tabs>
          </Box>
        }

        <Routes key={value}>
          <Route path='/history' element={<ChatHistory />} />
          <Route path='/sync' element={<ChatSync />} />
          {singleModule() &&
            <Route key={modules[0].path} path={'/'} element={modules[0].component} />
          }
          {!singleModule() && modules.map((m) => (
            <Route key={m.path} path={m.path} element={m.component} exact />
          ))}
          {!singleModule() &&
            <Route path='/' element={<Home />} />
          }
        </Routes>
      </div>
    </div>
  )
}

const App = () => {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <AppInner />
      </BrowserRouter>
    </AppContextProvider>
  )
}
export default App;