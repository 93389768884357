import React from 'react';

const MessageHistory = (props) => {
  return (
    <>
      {props.messages && props.messages.map(({ id, text, isUser }) => (
        <div key={id} className="mr-2">
          {!isUser &&
            <div className="w-full relative flex flex-col items-start mb-1">
              <div className="bg-gray-300 text-black inline-block p-2 rounded-xl rounded-bl-none">
                <p className='bot-message whitespace-pre-wrap'>
                  {text}
                </p>
              </div>
            </div>}
          {isUser && <div className="w-full relative flex flex-col items-end mb-1">
            <div className="bg-black text-white inline-block p-2 rounded-xl rounded-br-none">
              <p className='user-message whitespace-pre-wrap'>
                {text}
              </p>
            </div>
          </div>}
        </div>
      ))}
    </>
  );
}

export default MessageHistory;
